html {
  position: relative;
  min-height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", Georgia, sans-serif, serif;
}

.rc-tooltip {
  opacity: 1;
}

.btn-default {
  background: rgba(0, 0, 0, 0.06);
  font-size: 85%;
}

.btn-axis-switch:focus,
.btn-axis-switch:active,
.btn-axis-switch:active:focus {
  outline: none;
  box-shadow: none;
}

.badge {
  font-size: 85%;
  border-radius: 8px;
}
