.home-page-logo {
    font-size: 48px;
}

.home-page-logo-db {
    color: #666;
}

.home-page-logo-title {
    font-size: 16px;
}

.home-page-logo-title-info {
    color: #666;
    font-style: italic;
}
