.bg-mskcc-footer {
    background-color: #737373;
}

.mskcc-footer {
    color: #ffffff;
    font-size: 80%;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 5px 20px;
}

.mskcc-footer a,
.mskcc-footer a:hover,
.mskcc-footer a:focus,
.mskcc-footer a:active,
.mskcc-footer a:visited {
    color: #ffffff;
}

.mskcc-footer a {
    border-right: 0.5px solid #fff;
    padding: 2px 10px;
}

.mskcc-footer .row {
    margin-bottom: 10px;
}

.mskcc-footer a:last-child {
    border: 0;
}